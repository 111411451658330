import { render, staticRenderFns } from "./ChatMessage.vue?vue&type=template&id=031f3ad1"
import script from "./ChatMessage.vue?vue&type=script&lang=js"
export * from "./ChatMessage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports