<template>
    <x-preloader-block :loading="loading">
        <div
            :id="elementId"
            :class="elementClass"
        >
            <div
                :id="uid"
                :class="wrapperClasses"
            >
                <chat-head
                    :chatbox="chatbox"
                    :extended="!unknownSender"
                    :assigned="chatData.assigneeName"
                    :title="chatData.title"
                    :phone="chatData.phone"
                    :is-chat-active="chatData.active"
                    :actions="chatActions"
                    :customer-id="customerId"
                    :is-lead="chatData.senderType === 'lead'"
                    :profile-name="chatboxTitle"
                    :messenger-name="messengerName"
                    :mass-action-select-enabled="massSelectionActive"
                    @view-chat="viewChat"
                    @create-from-unknown="createFromUnknown"
                    @close-chat="closeChat(chatId)"
                    @re-assign-chat="reAssignChatModal"
                    @link-sender="connectChat"
                    @mass-action-link-ticket="massLinkMessagesToTicket"
                    @mass-action-create-ticket="massCreateTicketAndLinkToMessages"
                    @mass-action-select-enabled="setMassActionSelectEnabled"
                    @back="$emit('back')"
                />
                <div class="messages-view-block">
                    <div :class="topLoadingClasses">
                        <transition name="fade">
                            <x-bootstrap-spinner
                                v-if="messagesTopLoading"
                                size="sm"
                            />
                        </transition>
                    </div>
                    <div
                        ref="scroll"
                        class="messages-scroll-block splynx-photo-swiper"
                        :style="scrollBlockStyle"
                    >
                        <chat-conversation
                            v-for="conversation of conversationToRender"
                            :id="conversation.id"
                            :key="conversation.id"
                            :chat-id="chatId"
                            :chat-customer-id="customerId"
                            :messenger="messenger"
                            :messages="conversation.messages"
                            :date-created="conversation.dateCreated"
                            :sender-type="senderType"
                            :mass-selection-active="massSelectionActive"
                            :mass-selected-ids="selectedItems"
                            @mass-message-select="massAddSelectedElement"
                            @mass-message-deselect="massRemoveSelectedElement"
                            @show-message-location="showMessageLocation"
                        />
                    </div>
                    <transition name="fade">
                        <div
                            v-if="displayScrollDownButton"
                            class="messages-scroll-down-button"
                            @click="scrollDown"
                        >
                            <i class="icon-ic_fluent_arrow_down_24_regular" />
                        </div>
                    </transition>
                    <div class="d-flex justify-content-center align-items-center p-8">
                        <transition name="fade">
                            <x-bootstrap-spinner
                                v-if="messagesBottomLoading"
                                size="sm"
                            />
                        </transition>
                    </div>
                </div>
                <div
                    class="messages-input-block"
                >
                    <chat-field
                        :loading="processing"
                        :active="chatData.active"
                        :can-admin-write="chatData.canAdminWrite"
                        :check-access-controller="chatData.checkAccessController"
                        :check-access-action="chatData.checkAccessAction"
                        :chatbox="chatbox"
                        @reassign-to-me="reAssignToMe(chatId)"
                        @start-conversation="startConversation"
                        @send="sendMessage"
                        @set-scroll-block-height="onScrollBlockHeightChange"
                    />
                </div>
            </div>
        </div>
    </x-preloader-block>
</template>

<script>
import XPreloaderBlock from '@/components/common/XPreloaderBlock/XPreloaderBlock.vue';
import ChatParts from '@/apps/admin/mixins/messengers/chat-parts';
import ChatLive from '@/apps/admin/mixins/messengers/chat-live';
import ChatHead from '@/apps/admin/views/admin/common/communication/messengers/ChatHead.vue';
import ChatField from '@/apps/admin/views/admin/common/communication/messengers/ChatField.vue';
import ChatConversation from '@/apps/admin/views/admin/common/communication/messengers/ChatConversation.vue';
import { XBootstrapSpinner } from '@/components/common/XBootstrapSpinner';
import ChatScroll from '@/apps/admin/mixins/messengers/chat-scroll';
import MessageStatusUpdate from '@/apps/admin/mixins/messengers/message-status-update';
import InterceptNotification from '@/apps/admin/mixins/messengers/intercept-notification';
import ChatTicketLink from '@/apps/admin/mixins/messengers/chat-ticket-link';
import ChatMassActions from '@/apps/admin/mixins/messengers/chat-mass-actions';
import { withGet } from '@/utils/functions';

export default {
    name: 'ChatView',
    mixins: [ChatParts, ChatLive, ChatScroll, MessageStatusUpdate, InterceptNotification, ChatTicketLink, ChatMassActions],
    props: {
        chatbox: {
            type: Boolean,
            default: false,
        },
        senderType: {
            type: String,
            default: 'customer',
        },
        unknownSender: {
            type: Boolean,
            default: false,
        },
        viewHeight: {
            type: [String, Number],
            default: null,
        },
        scrollToMessageOnOpen: {
            type: [Boolean, Number, String],
            default: false,
        },
        messenger: [String, Number],
        messengerName: {
            type: String,
            default: '',
        },
        chatId: [String, Number],
        customerId: [String, Number],
        chatboxTitle: String,
    },
    data() {
        return {
            scrollBlockHeightBias: 148,
            processing: false,
            loading: true,
        };
    },
    created() {
        this.centralMessage = this.messageSearch;
        Promise.all([
            this.loadPart(),
            this.loadChatData(),
        ]).then(() => {
            this.loading = false;
            this.messagesBottomLoading = false;

            this.$nextTick(() => {
                this.showScrollButton();
                this.scrollToMessageOrEnd();
                this.initScrollListeners();
            });
        });
    },
    methods: {
        onScrollBlockHeightChange({ height, isScroll }) {
            this.scrollBlockHeightBias = height;
            this.$nextTick(() => {
                isScroll && this.scrollEnd();
            });
        },
        viewChat() {
            const refreshRoute = this.$route.path === '/admin/crm/leads/view'
                || this.$route.path === '/admin/customers/view';

            if (!this.customerId) {
                open_messenger_chat(this.chatData.id);
                this.$closeChatBox();
                return false;
            }
            let url = '';
            if (this.chatData.senderType === 'lead') {
                url = '/admin/crm/leads/view';
            } else {
                url = '/admin/customers/view';
            }
            switch_page(`${withGet(url, {
                id: this.customerId,
                chat_id: this.chatData.id,
                messenger: this.messenger,
                messengers: '',
            })}#communication`).then(() => {
                if (refreshRoute) {
                    this.$root.REFRESH_ROUTE();
                }
                this.$closeChatBox();
            });
        },
        startConversation() {
            if (this.chatbox) {
                this.$emit('start-conversation', {
                    customerId: this.customerId,
                    phone: this.chatData.phone,
                    messengerId: this.messenger,
                    messengerTitle: null,
                });
                return;
            }

            window.restore_messenger_chat(
                this.customerId,
                this.chatData.startConversationData,
                this.chatData.phone,
                this.messenger,
            );
        },
        async connectChat() {
            const { chatId } = this;
            window.showModal('messenger-connect-chat', {
                chatId,
                onSubmit: () => {
                    this.loadChatData();
                    window.reloadLastDataTable();
                },
            });
        },
        async reAssignChatModal() {
            const { chatId } = this;
            window.showModal('messenger-app-re-assign-chat', {
                chatId,
                type: this.senderType,
                onSubmit: () => {
                    this.loadChatData();
                },
            });
        },
        createFromUnknown() {
            const { phone, profileName } = this.chatData;
            showModal('messenger-create-from-unknown', {
                fullName: profileName,
                phoneNumber: phone,
            });
        },
        showMessageLocation(data) {
            showModal('show-messenger-message-location', data);
        },
    },
    computed: {
        uid() {
            return `chat_${this._uid}`;
        },
        scrollBlockStyle() {
            if (!this.chatbox) {
                return {};
            }
            return {
                height: this.viewHeight,
                maxHeight: `calc(100% - ${this.scrollBlockHeightBias}px)`,
            };
        },
        topLoadingClasses() {
            return {
                'd-flex justify-content-center align-items-center': true,
                'p-8': this.messagesTopLoading || !this.chatbox,
            };
        },
        wrapperClasses() {
            return {
                'messenger-chat-block': true,
                'chatbox-chat-view': this.chatbox,
            };
        },
        elementId() {
            if (this.chatbox) {
                return '';
            }
            if (this.unknownSender) {
                return '';
            }
            return 'communication_messengers_tab';
        },
        elementClass() {
            if (this.chatbox) {
                return '';
            }
            if (this.unknownSender) {
                return 'h-100';
            }
            return 'main-tab-holder h-100';
        },
    },
    components: {
        XBootstrapSpinner,
        XPreloaderBlock,
        ChatHead,
        ChatField,
        ChatConversation,
    },
};
</script>
